import React, { Fragment, useContext, useEffect, useState } from "react";

import JobItem from "./JobItem";
import JobContext from "../../context/job/jobContext";
import AuthContext from "../../context/auth/authContext";
import { haveUser } from "../../utils/common";

const JobList = () => {
  const jobContext = useContext(JobContext);
  const { currentJob, jobs, getJobs } = jobContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [filter, setFilter] = useState("");

  useEffect(() => {
    console.log("here");
    getJobs();
    // eslint-disable-next-line
  }, [currentJob]);

  const loggedIn = haveUser(user);

  const filterJobs = (userId) => {
    if (userId) {
      getJobs({ user: userId });
      setFilter("mine");
    } else {
      getJobs();
      setFilter("");
    }
  };

  const showFilterButtons = () => (
    <div className="btn-group">
      <button
        className={`btn btn-primary ${
          !filter || filter === "all" ? "active" : ""
        }`}
        key="showjobs-all"
        onClick={() => filterJobs("")}
      >
        All
      </button>
      <button
        className={`btn btn-success  ${filter === "mine" ? "active" : ""}`}
        key="showjobs-mine"
        onClick={() => filterJobs(user._id)}
      >
        Mine
      </button>
    </div>
  );

  return (
    <Fragment>
      <div className="container" key="joblist-container">
        <div className="row my-2" key="joblist-r1">
          <div className="col-4" key="joblist-r1-c1">
            <h4>JOBS</h4>
          </div>
          <div className="col-4" key="joblist-r1-c2">
            {loggedIn ? showFilterButtons() : ""}
          </div>
          <div className="col-4" key="joblist-r1-c3">
            <div className="row my-2" key="joblist-r1">
              <div className="col-12" key="joblist-r1-c1">
                <div
                  key={`gridItem-flex-2`}
                  className="d-flex align-items-end flex-column bd-highlight"
                >
                  <div key={`gridItem-flex-format-2`} className="bd-highlight">
                    {loggedIn ? (
                      <h4>{user && user?.name ? user.name : "No Name"}</h4>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row my-2" key="joblist-r1">
                <div className="col-12" key="joblist-r1-c2">
                  <div
                    key={`gridItem-flex-2`}
                    className="d-flex align-items-end flex-column bd-highlight"
                  >
                    <div
                      key={`gridItem-flex-format-2`}
                      className="bd-highlight"
                    >
                      <button type="button" className="btn btn-sm btn-primary">
                        ADD JOB
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row row-cols-1 row-cols-md-1 g-4" key="joblist-r2"> */}
        <div className="row row-cols-1 row-cols-md-2 g-4">
          {/* <div className="col" key="joblist-r2-c1"> */}
          {/* <div
              className="card-group m-2 p-2 g-2 row-cols-md-2"
              key="joblist-cg-r2-c1"
            > */}
          {/* <div
              className="card-group m-2 p-2 g-2 row-cols-md-2"
              key="joblist-cg-r2-c1"
            > */}
          {jobs ? (
            jobs.map((job, idx) => (
              <div className="col" key={"joblist-r2-c1-" + job._id}>
                <JobItem job={job} key={"jobItem-" + job._id} />
              </div>
            ))
          ) : (
            <Fragment key={"fragment"}>
              <div className="card">No Jobs, please add a job.</div>
            </Fragment>
          )}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default JobList;
