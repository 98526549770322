export const GET_JOB = "GET_JOB";
export const GET_JOBS = "GET_JOBS";
export const ADD_JOB = "ADD_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const CLEAR_JOBS = "CLEAR_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";
export const FILTER_JOBS = "FILTER_JOBS";
export const SET_CURRENT_JOB = "SET_CURRENT_JOB";
export const CLEAR_CURRENT_JOB = "CLEAR_CURRENT_JOB";
export const CLEAR_JOB_FILTER = "CLEAR_JOB_FILTER";
export const JOB_ERROR = "JOB_ERROR";
export const CLEAR_JOB_ERRORS = "CLEAR_JOB_ERRORS";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANYS = "GET_COMPANYS";
export const GET_USERS_COMPANYS = "GET_USERS_COMPANYS";
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const CLEAR_COMPANYS = "CLEAR_COMPANYS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const FILTER_COMPANYS = "FILTER_COMPANYS";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const CLEAR_CURRENT_COMPANY = "CLEAR_CURRENT_COMPANY";
export const CLEAR_COMPANY_FILTER = "CLEAR_COMPANY_FILTER";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const CLEAR_COMPANY_ERRORS = "CLEAR_COMPANY_ERRORS";

export const ADD_BID = "ADD_BID";
export const APPROVE_BID = "APPROVE_BID";
export const GET_BID = "GET_BID";
export const SET_CURRENT_BID = "SET_CURRENT_BID";
export const CLEAR_CURRENT_BID = "CLEAR_CURRENT_BID";
export const SHOW_HIDE_BIDS = "SHOW_HIDE_BIDS";

export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// AUTH CONSTS
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const UPDATE_MEMBER = "UPDATE_MEMBER";
