import React, { useEffect, useContext } from "react";
import JobTag from "../jobs/Jobs";
import AuthContext from "../../context/auth/authContext";
import * as Mui from "@mui/material";

const Jobs = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return (
    <Mui.Box sx={{ mt: 2 }}>
      <JobTag />
    </Mui.Box>
  );
};

export default Jobs;
