import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Private from "./components/routing/PrivateRoute";

import Home from "./components/pages/Home";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import AuthState from "./context/auth/AuthState";

import ResponsiveAppBar from "./components/layout/AppBar";

import AlertState from "./context/alert/AlertState";
import Alerts from "./components/layout/Alerts";

import Jobs from "./components/pages/Jobs";
import JobState from "./context/job/JobState";

import CompanyState from "./context/company/CompanyState";
import Companies from "./components/companies/Companies";

import Bids from "./components/pages/Bids";

import setAuthToken from "./utils/setAuthToken";

import CssBaseline from "@mui/material/CssBaseline";

// import logo from "./logo.svg";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "flatpickr/dist/flatpickr.css";

// import "../node_modules/flatpickr/dist/flatpickr.min.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AlertState>
      <AuthState>
        <CompanyState>
          <JobState>
            <Router>
              <Fragment>
                <CssBaseline />
                <Alerts />
                <ResponsiveAppBar />
                <div className="container">
                  <div className="row">
                    <div className="col-12 g-0">
                      <Routes>
                        <Route path="/" element={<Home />} />

                        <Route
                          exact
                          path="/companies"
                          element={<Private Component={Companies} />}
                        />
                        <Route
                          exact
                          path="/jobs"
                          element={<Private Component={Jobs} />}
                        />
                        <Route
                          exact
                          path="/bids"
                          element={<Private Component={Bids} />}
                        />
                        <Route
                          path="/bids/:bidId"
                          element={<Private Component={Bids} />}
                        />

                        <Route exact path="/register" element={<Register />} />
                        <Route exact path="/login" element={<Login />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              </Fragment>
            </Router>
          </JobState>
        </CompanyState>
      </AuthState>
    </AlertState>
  );
};

export default App;
