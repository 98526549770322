import {
  GET_COMPANYS,
  GET_USERS_COMPANYS,
  ADD_COMPANY,
  // DELETE_COMPANY,
  // CLEAR_COMPANYS,
  UPDATE_COMPANY,
  // FILTER_COMPANYS,
  // CLEAR_FILTER,
  SET_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  CLEAR_ERRORS,
  UPDATE_MEMBER,
} from "../types";

const companyReducer = (state, action) => {
  switch (action.type) {
    case GET_COMPANYS:
    case ADD_COMPANY:
    case UPDATE_MEMBER:
    case UPDATE_COMPANY:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    case GET_USERS_COMPANYS:
      return {
        ...state,
        usersCompanies: action.payload,
        loading: false,
      };

    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: null,
        loading: false,
      };

    // case COMPANY_ERROR:
    //   return {
    //     ...state,
    //     companies: false,
    //     loading: false,
    //     error: action.payload,
    //   };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default companyReducer;
