import React, { useReducer } from "react";
import axios from "axios";
import JobContext from "./jobContext";
import jobReducer from "./jobReducer";

import {
  GET_JOBS,
  ADD_JOB,
  ADD_BID,
  // APPROVE_BID,
  SHOW_HIDE_BIDS,
  UPDATE_MEMBER,
  // DELETE_JOB,
  CLEAR_JOBS,
  UPDATE_JOB,
  // FILTER_JOBS,
  SET_CURRENT_JOB,
  CLEAR_CURRENT_JOB,
  SET_CURRENT_BID,
  CLEAR_CURRENT_BID,
  // CLEAR_FILTER,
  JOB_ERROR,
  // CLEAR_ERRORS,
} from "../types";

const JobState = (props) => {
  const initialState = {
    jobs: null,
    currentJob: null,
    currentBid: null,
    loading: true,
    error: null,
    showBids: [],
  };

  const [state, dispatch] = useReducer(jobReducer, initialState);

  // Load User
  const getJobs = async (filter) => {
    // if token exists, set token in config
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // try getting user from auth
      const res = await axios.post("/api/jobs", filter, config);
      // const res = await axios.get("/api/jobs");

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_JOBS,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: CLEAR_JOBS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log("got error");
      dispatch({ type: JOB_ERROR });
    }
  };

  // Login User
  const addJob = async (job, legs) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/jobs/add", { job, legs }, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: ADD_JOB,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: JOB_ERROR,
      });
    }
  };

  // Login User
  const addNewBid = async (jobId, bid) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/jobs/bids/add",
        { jobId, bid },
        config
      );

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: ADD_BID,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: JOB_ERROR,
      });
    }
  };

  // Login User
  const actionTheJob = async (jobId, bidId, actionToTake) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      await axios.post(
        "/api/jobs/action",
        { jobId, bidId, actionToTake },
        config
      );
      getJobs();

      // send to reducer to log token in local storage
      //   and mark as authenticated
      // dispatch({
      //   type: APPROVE_BID,
      //   payload: res.data,
      // });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: JOB_ERROR,
      });
    }
  };

  // Login User
  const updateMembers = async (jobId, userId, action) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/jobs/updateMembers",
        { jobId, userId, action },
        config
      );

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: UPDATE_MEMBER,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: JOB_ERROR,
      });
    }
  };

  // Login User
  const updateJob = async (job, legs) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/jobs/update/", { job, legs }, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: UPDATE_JOB,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: JOB_ERROR,
      });
    }
  };

  const showHideBids = (data) => {
    dispatch({ type: SHOW_HIDE_BIDS, payload: data });
  };

  const setCurrentJob = (data) => {
    dispatch({ type: SET_CURRENT_JOB, payload: data });
  };

  const clearCurrentJob = () => {
    dispatch({ type: CLEAR_CURRENT_JOB });
  };

  const setCurrentBid = (data) => {
    dispatch({ type: SET_CURRENT_BID, payload: data });
  };

  const clearCurrentBid = () => {
    dispatch({ type: CLEAR_CURRENT_BID });
  };

  return (
    <JobContext.Provider
      value={{
        getJobs,
        addJob,
        addNewBid,
        actionTheJob,
        updateMembers,
        updateJob,
        jobs: state.jobs,
        setCurrentJob,
        clearCurrentJob,
        currentJob: state.currentJob,
        setCurrentBid,
        clearCurrentBid,
        currentBid: state.currentBid,
        loading: state.loading,
        showHideBids,
        showBids: state.showHideBids,
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
};

export default JobState;
