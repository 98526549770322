import React, { useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import * as Mui from "@mui/material";
import BidTag from "../bids/Bids";

const Bids = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return (
    <Mui.Box sx={{ mt: 2 }}>
      <BidTag />
    </Mui.Box>
  );
};

export default Bids;
