import React, { Fragment, useContext, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import CompanyContext from "../../context/company/companyContext";

import { emptyArr } from "../../utils/functionsCommon";

const CompanyDropDown = ({ visible }) => {
  const companyContext = useContext(CompanyContext);
  const {
    usersCompanies,
    getUsersCompanies,
    currentCompany,
    setCurrentCompany,
    clearCurrentCompany,
    loading,
  } = companyContext;

  const options = {
    getFunction: getUsersCompanies,
    collection: usersCompanies,
    current: currentCompany,
    setFunction: setCurrentCompany,
    clearFunction: clearCurrentCompany,
    checkQueryString: "select_company",
    indexField: "_id",
    displayField: "name",
    title: "Companies",
    showIfNoValue: true,
  };

  // const location = useLocation();

  useEffect(() => {
    // let querySelected = getQueryElement(
    //   location.search,
    //   options.checkQueryString
    // );
    // if (querySelected) {
    // }

    if (visible) {
      let forceRefresh = false;
      options.getFunction(forceRefresh);
    }
    // eslint-disable-next-line
  }, [visible]);

  const setContracts = (e) => {
    setTheCollection(e.target.value);
  };

  const setTheCollection = (selectedOption) => {
    if (selectedOption && selectedOption !== "notSelected") {
      let collectionList = getCollectionObject(selectedOption);
      options.setFunction(collectionList);
    } else {
      options.clearFunction();
    }
  };

  const getCollectionObject = (selectedOption) => {
    if (!options.collection) {
      return null;
    } else {
      let col = options.collection.filter(
        (collection) =>
          collection[options.indexField].toString() ===
          selectedOption.toString()
      );
      return col[0];
    }
  };

  // const getQueryElement = (queryString, queryName) => {
  //   queryString = decodeURIComponent(queryString);
  //   if (queryString && queryString.includes(queryName)) {
  //     queryString = queryString.substr(1, queryString.length - 1);
  //     let locations = queryString.split("&");
  //     let foundQueryPair = locations.filter(
  //       (loc) => loc.indexOf(queryName) === 0
  //     );
  //     if (!!foundQueryPair && foundQueryPair.length > 0) {
  //       let queryValue = foundQueryPair[0].split("=");
  //       return queryValue[1];
  //     }
  //   }
  //   return false;
  // };

  const selectNoValue = () => (
    <option key="notSelected-1" value="notSelected">
      No {options.title}
    </option>
  );
  const selectLoading = () => (
    <option key="notSelected-2" value="notSelected">
      loading ...
    </option>
  );

  const selectPleaseSelect = () => (
    <option key="notSelected-3" value="notSelected">
      Please select.
    </option>
  );

  const hasValues =
    options.collection !== null && !emptyArr(options.collection);
  const hasSelected = options.current;
  const selectedCollection = hasSelected ? options.current : null;

  return (
    <Fragment>
      {hasValues || options.showIfNoValue ? (
        <span>
          <select
            name={options.checkQueryString}
            className="form-select form-select-sm"
            value={
              hasValues && hasSelected
                ? selectedCollection[options.indexField]
                : "Please Select."
            }
            onChange={(e) => {
              setContracts(e);
            }}
          >
            {hasValues
              ? selectPleaseSelect()
              : loading
              ? selectLoading()
              : selectNoValue()}
            {hasValues
              ? options.collection.map((item, key) => (
                  <option
                    key={item[options.indexField] + "-" + key}
                    value={item[options.indexField]}
                  >
                    {item[options.displayField]}
                  </option>
                ))
              : ""}
          </select>
        </span>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default CompanyDropDown;
