import React, { useContext, useEffect } from "react";

import { List } from "@mui/material";

import CompanyContext from "../../context/company/companyContext";
import AuthContext from "../../context/auth/authContext";
import { showFields } from "../../utils/functionsCommon";
import { useState } from "react";
import CompanyDropDown from "./CompanyDropDown";

const Companies = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext;

  const companyContext = useContext(CompanyContext);
  const {
    // companies,
    // getCompanies,
    currentCompany,
    // usersCompanies,
    getUsersCompanies,
  } = companyContext;

  const fields = [
    { field: "name", title: "Name", type: "string" },
    { field: "members", title: "Members", type: "list" },
    { field: "email", title: "Email", type: "string" },
    { field: "website", title: "Website", type: "string" },
    { field: "logo", title: "Logo", type: "string" },
    { field: "suburb", title: "Suburb", type: "string" },
  ];
  const defaultCompany = fields.map((field) => ({ [field.field]: "" }));
  const [company, setCompany] = useState(defaultCompany);

  const onChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const onDateClear = () => {};

  useEffect(() => {
    if (isAuthenticated) getUsersCompanies(user._id);
    if (currentCompany) setCompany(currentCompany);
    // eslint-disable-next-line
  }, [currentCompany]);

  let mode = "read";
  const args = {
    fields: fields,
    record: mode === "read" ? currentCompany : company,
    onChange: onChange,
    onDateClear: onDateClear,
    add: false,
    mode: "read",
    isMobile: true,
    arrays: [],
  };

  // let btns = {
  //   back: {
  //     action: "back",
  //     title: "BACK",
  //     iconName: "arrow_back",
  //     tt: "",
  //     // disabled: !currentHoliday,
  //   },
  //   edit: {
  //     action: "edit",
  //     title: "",
  //     iconName: "edit",
  //     tt: mode === "edit" ? "Edit Holiday" : "",
  //     disabled: !currentCompany,
  //   },
  //   pdf: {
  //     action: "pdf",
  //     title: "PDF",
  //     iconName: "download",
  //     tt: !currentCompany ? "Please select holiday" : "Download Holiday",
  //     disabled: !currentCompany,
  //   },
  //   save: {
  //     action: "save",
  //     title: "SAVE",
  //     iconName: "save",
  //     tt: !currentCompany ? "Please select holiday" : "Save Holiday",
  //     disabled: !currentCompany,
  //   },
  //   "save-edit": {
  //     action: "save-edit",
  //     title: "",
  //     iconName: "done",
  //     tt: mode === "edit" ? "Save Holiday" : "",
  //   },
  //   cancel: {
  //     action: "cancel",
  //     title: "",
  //     iconName: "close",
  //     tt: mode === "edit" ? "Cancel editing without saving" : "",
  //   },
  // };

  // if (usersCompanies) setCompany(usersCompanies[0]);

  if (!isAuthenticated) return "Please Login First";
  return (
    <List key="list-0">
      <div className="container">
        <div className="row p-1">
          <div className="col">
            <CompanyDropDown />
          </div>
          <div className="col">&nbsp;</div>
        </div>
        <div className="row  p-1">
          <div className="col">{showFields(args)}</div>
          <div className="col">&nbsp;</div>
        </div>
      </div>
    </List>
  );
};

export default Companies;
