import {
  GET_JOBS,
  ADD_JOB,
  ADD_BID,
  APPROVE_BID,
  UPDATE_MEMBER,
  // DELETE_JOB,
  // CLEAR_JOBS,
  UPDATE_JOB,
  // FILTER_JOBS,
  // CLEAR_FILTER,
  JOB_ERROR,
  SET_CURRENT_JOB,
  CLEAR_CURRENT_JOB,
  SET_CURRENT_BID,
  CLEAR_CURRENT_BID,
  CLEAR_ERRORS,
  SHOW_HIDE_BIDS,
} from "../types";

const jobReducer = (state, action) => {
  switch (action.type) {
    case GET_JOBS:
    case ADD_JOB:
    case ADD_BID:
    case APPROVE_BID:
    case UPDATE_MEMBER:
    case UPDATE_JOB:
      return {
        ...state,
        jobs: action.payload,
        loading: false,
      };

    case SHOW_HIDE_BIDS:
      let bidInArray = state.showBids.includes(action.payload);
      let emptyOrNoLength = !state.showBids || !state.showBids.length;
      return {
        ...state,
        showBids:
          !bidInArray || emptyOrNoLength
            ? [...state.showBids, action.payload]
            : state.showBids.filter((bids) =>
                bids === action.payload ? false : true
              ),
      };

    case SET_CURRENT_JOB:
      return {
        ...state,
        currentJob: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_JOB:
      return {
        ...state,
        currentJob: null,
        loading: false,
      };

    case SET_CURRENT_BID:
      return {
        ...state,
        currentBid: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_BID:
      return {
        ...state,
        currentBid: null,
        loading: false,
      };

    case JOB_ERROR:
      return {
        ...state,
        jobs: null,
        currentJob: false,
        currentDay: null,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default jobReducer;
