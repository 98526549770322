import React, { useEffect, useState, useContext } from "react";

import JobContext from "../../context/job/jobContext";

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import AuthContext from "../../context/auth/authContext";
import {
  bidStatuses,
  // statuses
} from "../../utils/common";

const BidItem = ({ jobId }) => {
  const jobContext = useContext(JobContext);
  const { currentBid } = jobContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // const BidItem = ({ newBid, setNewBid, onFormChange, jobId }) => {
  const ccyList = ["usd", "aud", "sgd"];
  // const statusList = ["Submitted", "Bid Confirmed", "Not Selected", "Selected"];

  const defaultBid = {
    job: "",
    status: "",
    company: "",
    contact: "",
    phone: "",
    ccy: "",
    amount: "",
    startDate: "",
    comments: "",
    attachments: [],
    pictures: [],
  };
  const [newBid, setNewBid] = useState(defaultBid);
  // const [editBid, setEditBid] = useState(defaultBid);

  useEffect(() => {
    setNewBid(currentBid);

    // eslint-disable-next-line
  }, []);

  const onFormChange = (e) => {
    setNewBid({ ...newBid, [e.target.id]: e.target.value });
  };

  // const addBid = (e, jobId, newBid) => {
  //   addNewBid(jobId, newBid);
  // };

  let enabled = user?._id === newBid?.user;
  let k = jobId;
  return (
    <div className="container" key={`addBid-container-${k}`}>
      <div className="form-group" key={`addBid-formGroup-${k}`}>
        <label htmlFor="status" className="form-label">
          Status
        </label>
        <div className="input-group mb-1" key={`addBid-inputGroup-1-${k}`}>
          <select
            className="form-select form-select-sm"
            key={`addBid-input-1-${k}`}
            id="status"
            aria-label="Status"
            onChange={(e) => onFormChange(e)}
            disabled={!enabled}
          >
            {bidStatuses.map((status, idx) => (
              <option
                value={
                  newBid?.status ? newBid.status.toUpperCase() : "NOT SUBMITTED"
                }
                key={`addBid-status-option-1-${idx + "-" + k}`}
              >
                {status.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-1" key={`addBid-inputGroup-2-${k}`}>
          <label htmlFor="company" className="form-label">
            Company
          </label>
          <input
            placeholder="Company"
            className="form-control form-control-sm"
            key={`addBid-input-2-${k}`}
            id="company"
            onChange={(e) => onFormChange(e)}
            value={newBid.company}
            disabled={!enabled}
          />
        </div>
        <div className="mb-1" key={`addBid-inputGroup-contact-${k}`}>
          <label htmlFor="contact" className="form-label">
            Contact
          </label>
          <input
            placeholder="Contact"
            className="form-control form-control-sm"
            key={`addBid-input-contact-${k}`}
            id="contact"
            onChange={(e) => onFormChange(e)}
            value={newBid.contact}
            disabled={!enabled}
          />
        </div>
        <div className="mb-1" key={`addBid-inputGroup-phone-${k}`}>
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            placeholder="Phone"
            className="form-control form-control-sm"
            key={`addBid-input-phone-${k}`}
            id="phone"
            onChange={(e) => onFormChange(e)}
            value={newBid.phone}
            disabled={!enabled}
          />
        </div>
        <label htmlFor="ccy" className="form-label">
          Bid Amount
        </label>
        <div className="input-group mb-1" key={`addBid-inputGroup-3-${k}`}>
          <select
            className="form-select form-select-sm"
            key={`addBid-input-3-${k}`}
            id="ccy"
            aria-label="Currency Selection"
            onChange={(e) => onFormChange(e)}
            disabled={!enabled}
          >
            {ccyList.map((ccy, idx) => (
              <option
                value={newBid.ccy}
                key={`addBid-ccy-option-1-${idx + "-" + k}`}
              >
                {ccy.toUpperCase()}
              </option>
            ))}
          </select>
          {/* <label htmlFor="amount" className="form-label">
            Amount
          </label> */}
          <input
            placeholder="Amount"
            className="form-control form-control-sm"
            key={`addBid-input-4-${k}`}
            id="amount"
            onChange={(e) => onFormChange(e)}
            value={newBid.amount}
            disabled={!enabled}
          />
        </div>
        {/* <div className="mb-1" key={`addBid-inputGroup-4-${k}`}>
          <input
            placeholder="Start Date"
            key={`addBid-input-startDate-${k}`}
            className="form-control form-control-sm"
            id="startDate"
            onChange={(e) => onFormChange(e)}
            value={newBid.startDate}
          />
        </div> */}
        <label htmlFor="startDate" className="form-label">
          Start
        </label>
        <div className="input-group mb-1" key={`addBid-inputGroup-5-${k}`}>
          <input
            id={`dateStart`}
            placeholder="Date Start"
            className="form-control form-control-sm"
            key={`addBid-input-5-${k}`}
            type="date"
            aria-describedby={`button-dateStart picker-addon-dateStart`}
            aria-label={`dateStart`}
            aria-labelledby={`dateStart`}
            title={`dateStart`}
            value={newBid.dateStart}
          />
          <button
            type="button"
            key={`addBid-closeButton-1-${k}`}
            id={`button-dateStart`}
            className="btn btn-outline-secondary btn-sm"
            // onClick={(e, a) => args.onDateClear(clearArgs, "clear")}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="mb-1" key={`addBid-inputGroup-6-${k}`}>
          <label htmlFor="comments" className="form-label">
            Comments
          </label>
          <textarea
            placeholder="Comments"
            className="form-control  form-control-sm"
            key={`addBid-input-6-${k}`}
            id="comments"
            onChange={(e) => onFormChange(e)}
            value={newBid.comments}
            rows="3"
            disabled={!enabled}
          ></textarea>
        </div>
      </div>
    </div>
    // <div
    //   className="modal fade"
    //   id="addModal"
    //   key={`bidAdd-modal-${k}`}
    //   tabIndex="-1"
    //   aria-labelledby="addModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog" key={`bidAdd-modal-dialog-${k}`}>
    //     <div className="modal-content" key={`bidAdd-modal-content-${k}`}>
    //       <div className="modal-body" key={`bidAdd-modal-body-${k}`}>

    //       </div>
    //       <div className="modal-footer" key={`bidAdd-modal-footer-${k}`}>
    //         <button
    //           type="button"
    //           className="btn btn-primary"
    //           key={`bidAdd-submit-${k}`}
    //           onClick={(e) => addBid(e, jobId, newBid)}
    //         >
    //           Submit
    //         </button>
    //         <button
    //           type="button"
    //           className="btn btn-secondary"
    //           key={`bidAdd-modal-dismiss-${k}`}
    //           data-bs-dismiss="modal"
    //         >
    //           Cancel
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BidItem;
