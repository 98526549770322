import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import JobTag from "../jobs/Jobs";

const Home = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    console.log("at the home.js page");
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <JobTag />
      </div>
    </div>
  );
};
// const Home = () => {
//   const authContext = useContext(AuthContext);

//   useEffect(() => {
//     console.log("at the home.js page");
//     authContext.loadUser();
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <div>
//       <div>
//         <h1 className="text-primary">Welcome to Holiday Planner</h1>
//         <span>Please click a link below to start</span>
//         <br />
//       </div>
//       <div>
//         <br />
//         <ul>
//           <li>
//             <Link to="/legs">Holiday Planner</Link>
//           </li>
//         </ul>
//       </div>
//       <div>
//         <br />
//         <ul>
//           <li>
//             <Link to="/jobs">Jobs</Link>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

export default Home;
