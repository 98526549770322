import React, { Fragment, useContext, useEffect } from "react";

import AuthContext from "../../context/auth/authContext";
import JobContext from "../../context/job/jobContext";
import { addBidButton } from "../../utils/common";
import BidList from "../bids/BidList";
import AddBid from "./AddBid";

const JobDetail = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const jobContext = useContext(JobContext);
  const { currentJob, clearCurrentJob } = jobContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const statusColors = {
    pending: "warning",
    posted: "primary",
    "in-progress": "success",
    completed: "secondary",
    cancelled: "dark",
  };

  const addBidModal = (k) => {
    return <AddBid key={`bidAdd-AddBidCtrl-${k}`} jobId={currentJob._id} />;
  };

  const clearJob = (e) => {
    clearCurrentJob();
  };

  // const showBidList = (bids, jobId) => {
  //   return (
  //     <table
  //       className="table table-striped mb-1"
  //       key={`bidList-table-${jobId}`}
  //     >
  //       <tbody>
  //         {bids.map((bid, idx) => {
  //           let k = jobId + "-" + idx;
  //           return (
  //             <tr key={`bidList-row-1-${k}`}>
  //               <td key={`bidList-col-1-${k}`} className="small">
  //                 <div
  //                   key={`bidList-flex-1-${k}`}
  //                   className="d-flex align-items-start flex-column bd-highlight"
  //                 >
  //                   <div
  //                     key={`bidList-txtFormat-1-${k}`}
  //                     className="bd-highlight"
  //                   >
  //                     {bid.company}
  //                   </div>
  //                 </div>
  //               </td>
  //               <td className="small" key={`bidList-col-2-${k}`}>
  //                 <div
  //                   className="d-flex align-items-center flex-column bd-highlight"
  //                   key={`bidList-flex-2-${k}`}
  //                 >
  //                   <div
  //                     key={`bidList-txtFormat-2-${k}`}
  //                     className="bd-highlight"
  //                   >
  //                     {bid.status}
  //                   </div>
  //                 </div>
  //               </td>
  //               <td className="small" key={`bidList-col-3-${k}`}>
  //                 <div
  //                   className="d-flex align-items-end flex-column bd-highlight "
  //                   // className="d-flex align-items-end"
  //                   key={`bidList-flex-3-${k}`}
  //                 >
  //                   <div
  //                     className="bd-highlight"
  //                     key={`bidList-txtFormat-3-${k}`}
  //                   >
  //                     ${bid.amount}
  //                   </div>
  //                 </div>
  //               </td>
  //               <td key={`bidList-col-4-${k}`} className="small">
  //                 <div
  //                   className="d-flex "
  //                   key={`bidList-flex-1-${k}`}
  //                   // className="d-flex align-items-start flex-column bd-highlight mb-1"
  //                 >
  //                   <div className="mt-auto align-items-end  p-0 bd-highlight"></div>
  //                 </div>
  //               </td>
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </table>
  //   );
  // };

  let k = currentJob._id;
  return (
    <Fragment>
      <div
        className="container mb-3 border-bottom"
        key={`jobItem-container-${k}`}
      >
        <div
          className="d-flex align-items-end flex-column bd-highlight"
          key={`bids-flex-1`}
        >
          <div className="bd-highlight" key={`bids-flex-format-1`}>
            <button
              className="btn btn-sm btn-primary"
              key={`bids-flex-backBtn-1`}
              onClick={(e) => clearJob(e)}
            >
              Back
            </button>
          </div>
        </div>

        <div className="row" key={`jobItem-row-1-${k}`}>
          <div className="col h5" key={`jobItem-row-1-col-1-${k}`}>
            {currentJob.category}
          </div>
          <div className="col small" key={`jobItem-row-1-col-2-${k}`}>
            <div
              key={`jobItem-row-1-col-2-flex-${k}`}
              className="d-flex align-items-end flex-column bd-highlight mb-1"
            >
              {/* <div
                key={`jobItem-row-1-col-2-format-${k}`}
                className="p-1 bd-highlight"
              > */}
              {/* {job.city} */}
              {currentJob.area}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="row" key={`jobItem-row-2-${k}`}>
          <div className="col" key={`jobItem-row-2-col-1-${k}`}>
            {currentJob.description}
          </div>
        </div>
        <div
          className="row mb-2 justify-content-between"
          key={`jobItem-row-3-${k}`}
        >
          <div className="col-6" key={`jobItem-row-3-col-1-${k}`}>
            <div
              key={`bidList-flex-1-${k}`}
              // className="d-flex align-items-start flex-column bd-highlight mb-1"
              className="d-flex "
            >
              <div className="mt-auto p-2 bd-highlight">
                <h5>
                  <span
                    className={`badge rounded-pill bg-${
                      statusColors[currentJob.status.toLowerCase()]
                    }`}
                  >
                    {currentJob.status}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-6" key={`jobItem-row-3-col-2-${k}`}>
            <div
              key={`bidList-flex-1-${k}`}
              className="d-flex align-items-end flex-column bd-highlight mb-1"
            >
              {addBidButton(k, user)}
            </div>
          </div>
        </div>
        {user && currentJob?.user && currentJob?.user === user._id ? (
          <BidList showMore={currentJob._id} job={currentJob} />
        ) : (
          ""
        )}
      </div>
      {/* {addBidModal(job._id, newBid)} */}
      {addBidModal(currentJob._id)}
    </Fragment>
  );
};

export default JobDetail;
