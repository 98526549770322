import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import FaceIcon from "@mui/icons-material/Face";

import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const menuBarTitle = "Bid for Jobs";
const pages = ["Home", "Register"];
const links = ["", "register"];
const privatePages = ["Home", "Jobs", "Calendar", "Companies"];
const privateLinks = ["", "jobs", "calendar", "companies"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout, loadUser, user } = authContext;

  const navigate = useNavigate();

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);
  // db-eslint-disable-next-line

  const onLogout = () => {
    logout();
    // clearContacts();
  };

  const onLogin = () => {
    navigate("/login");
    // clearContacts();
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e) => {
    if (e.target.textContent.toLowerCase() === "logout") {
      onLogout();
    }
    if (e.target.textContent.toLowerCase() === "login") {
      onLogout();
    }
    setAnchorElUser(null);
  };

  // dropdown account menu from avatar on device
  const loginMenu = () => (
    <MenuItem key="login-menu" onClick={onLogin}>
      <Typography textAlign="center">Login</Typography>
    </MenuItem>
  );

  // dropdown page menu on device
  const menuItem = (page) => (
    <MenuItem
      key={page}
      component={Link}
      to={"/" + page}
      onClick={handleCloseNavMenu}
    >
      <Typography textAlign="center">{page}</Typography>
    </MenuItem>
  );

  // menu across the top on web browser
  const loginButton = () => (
    <Button
      key="login-button"
      // to={"/login"}
      onClick={onLogin}
      sx={{ my: 2, color: "white", display: "block" }}
    >
      Login
    </Button>
  );

  // menu across the top on web browser
  const logoutButton = () => (
    <Button
      key="logout"
      // to={"/" + links[idx]}
      onClick={onLogout}
      sx={{ my: 2, color: "white", display: "block" }}
    >
      Logout
    </Button>
  );

  // menu across the top on web browser
  const buttonItem = (page, link) => (
    <Button
      key={page}
      component={Link}
      to={"/" + link}
      // onClick={handleCloseNavMenu}
      sx={{ my: 2, color: "white", display: "block" }}
    >
      {page}
    </Button>
  );

  const title = (type) => {
    let style = {
      small: { flexGrow: 1, display: { xs: "flex", md: "none" } },
      large: { mr: 2, display: { xs: "none", md: "flex" } },
    };
    return (
      <Typography variant="h6" noWrap component="div" sx={{ ...style[type] }}>
        {menuBarTitle}
      </Typography>
    );
  };

  const menuIcon = (
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenNavMenu}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {title("large")}

          {/* Drop down menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {menuIcon}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {isAuthenticated
                ? privatePages.map((page) => menuItem(page))
                : pages.map((page) => menuItem(page))}
            </Menu>
          </Box>

          {title("small")}
          {/* navigation links */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {isAuthenticated
              ? privatePages.map((page, idx) =>
                  buttonItem(page, privateLinks[idx])
                )
              : pages.map((page, idx) => buttonItem(page, links[idx]))}
            {isAuthenticated ? logoutButton() : loginButton()}
          </Box>

          {/* Menu Right */}
          <Box sx={{ flexGrow: 0 }}>
            {user && user?.name ? user.name : ""} &nbsp;
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user && user?.name ? user.name : "No Name"}
                  src={
                    "/" + (user && user?.avatar ? user.avatar : "avatar.png")
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{ mt: "45px" }}
            >
              {isAuthenticated
                ? settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))
                : loginMenu()}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
