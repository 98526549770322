import { DateTime } from "luxon";
import { Fragment } from "react";
import { Duration } from "luxon";

export const haveUser = (user) => user && user?._id;

export const checkBidIsUsers = (bids, user) =>
  bids.find((bid) => bid.user === user._id);

export const checkUserBidStatus = (bids, user, status) =>
  bids.find(
    (bid) =>
      bid?.user === user?._id &&
      bid?.status.toLowerCase() === status.toLowerCase()
  );

export const userCreatedJob = (job, user) =>
  haveUser(user) && job?.user && job.user._id === user._id;

export const userCreatedBid = (bid, user) =>
  haveUser(user) && bid?.user && bid.user === user._id;

export const userWonJob = (job, user, status) =>
  haveUser(user) &&
  job?.bids?.length &&
  checkUserBidStatus(job.bids, user, status);

export const userHasBid = (job, user) =>
  haveUser(user) && job?.bids?.length && checkBidIsUsers(job.bids, user);

export const statusColors = {
  posted: "primary",
  "bids posted": "warning",
  "bid selected": "warning",
  confirmed: "success",
  "in-progress": "success",
  completed: "secondary",
  pending: "warning",
  cancelled: "dark",
};

export const statuses = {
  posted: { title: "Posted", color: "primary", order: 0 },
  "bids posted": { title: "Bids Posted", color: "warning", order: 1 },
  "bid selected": { title: "Bid Selected", color: "warning", order: 2 },
  confirmed: { title: "Confirmed", color: "success", order: 3 },
  "in-progress": { title: "In Progress", color: "success", order: 4 },
  pending: { title: "Pending", color: "warning", order: 4 },
  completed: { title: "Completed", color: "secondary", order: 6 },
  overdue: { title: "Overdue", color: "danger", order: 5 },
  dispute: { title: "Dispute", color: "danger", order: 5 },
  cancelled: { title: "Cancelled", color: "secondary", order: 6 },
};

export const bidStatuses = [
  "Not Submitted",
  "Submitted",
  "Bid Confirmed",
  "Not Selected",
  "Selected",
];

export const addBidButton = (k, user) => {
  return (
    <span
      key={k + "-tooltip"}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={!user ? "Please login to add bid" : ""}
      className="m-0 p-0"
    >
      <button
        type="button"
        className="btn btn-sm btn-primary"
        key={`jobItem-btn-addBid-${k}`}
        data-bs-toggle="modal"
        data-bs-target="#addModal"
        disabled={!user}
      >
        ADD BID
      </button>
    </span>
  );
};

export const dbMsg = (lvl, dbl, msg) => {
  let dbgLevel = dbl === null || dbl === undefined ? 0 : dbl;
  if (dbgLevel >= lvl) console.log(msg);
};

export const checkIfMobile = () => {
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigator.userAgent.substring(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const dateFormats = {
  luxon: "dd-MMM-yy HH:mm",
  pdfDateTime: "dd-MMM-yy HH:mm",
  pdfDate: "dd-MMM-yy HH:mm",
  summaryDateTime: "dd-MMM-yyyy HH:mm",
  summaryDate: "dd-MMM-yyyy",
  summaryTime: "HH:mm",
  mongodb: "jsdate",
  // flatpickr: "D d-M-y H:i",
  fromDB: "iso",
  datePickerLuxon: "dd-MMM-yy HH:mm",
  datePickerPicker: "d-M-y H:i",
};

// input functions

export const textField = (idx, labelPrefix, fieldDef, opts) => {
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  let fieldIdName = { name: fieldDef.fieldName, id: fieldDef.fieldName };

  // let datePickerType = "react";
  let datePickerType = "flat";
  let isDatePicker =
    fieldDef?.options && fieldDef.options.includes("showDatePicker");

  opts = {
    ...opts,
    ...fieldIdName,
    key: keyId,
    type: "text",
  };

  if (fieldDef.type === "date") {
    if (isDatePicker) {
      opts.value = formatValue("datepicker", fieldDef.field, "");
    } else {
      opts.value = formatValue(fieldDef.type, fieldDef.field, fieldDef.format);
    }
  } else {
    opts.value = formatValue(fieldDef.type, fieldDef.field, "textField");
  }

  // opts.value = formatValue(fieldDef.type, fieldDef.field, fieldDef.format);
  if (opts.onchange !== undefined || opts.onchange !== null)
    opts.onChange = (e) => fieldDef.onchange(e, idx);

  if (isDatePicker) {
    opts.type = "date";
    opts.placeholder = datePickerType === "react" ? "" : fieldDef.fieldLabel;
    opts.onChange = (e, f, g) =>
      fieldDef.onchange(
        {
          target: { ...fieldIdName, value: e, type: "jsdate" },
          other: { f, g },
          // target: { ...fieldIdName, value: e, type: "jsdate" },
        },
        idx
      );
    opts.onClearDate = (e) =>
      fieldDef.onchange(
        {
          target: {
            ...fieldIdName,
            value: "",
          },
        },
        idx
      );
  }

  let isLocationSelect = fieldDef.type === "location";
  if (isLocationSelect) {
    delete opts.type;
    delete opts.value;
    opts.placeholder = fieldDef.fieldLabel;
    opts.onChange = (e) =>
      fieldDef.onchange(
        {
          target: {
            ...fieldIdName,
            value: e.value,
          },
          raw: { ...e },
        },
        idx
      );
  }

  return isLocationSelect ? (
    // <LocationFilter opts={opts} value={fieldDef.field} scope={fieldDef.set} />
    "Location Filter"
  ) : !isDatePicker ? (
    <div className="input-group mb-1 form-floating">
      <input
        {...opts}
        className="form-control form-control-sm"
        placeholder=""
        aria-label="Example text with button addon"
        aria-describedby="button-addon1"
      />
      <label htmlFor={keyId}>{fieldDef.fieldLabel}</label>
    </div>
  ) : (
    flatDatePcker(opts, idx)
  );
};

export const flatDatePcker = (opts, idx) => {
  // let dateClearClick = opts.onClearDate;
  delete opts.onClearDate;

  return (
    <div className="input-group">
      Flatpckr missing
      {/* <Flatpckr
        options={{
          // dateFormat: dateFormats.flatpickr,
          dateFormat: dateFormats.datePickerPicker,
          enableTime: "true",
          allowInput: "true",
          // wrap: true,
        }}
        // date-time-enable="true"
        opts={{ ...opts, className: "form-control" }}
        type="text"
        onchange={opts.onChange}
        oncleardate={dateClearClick}
        value={opts.value}
      /> */}
    </div>
  );
};

export const icon = (icon, fontSize, k) => {
  let opts = fontSize ? { style: { fontSize: fontSize } } : {};
  return (
    <span {...opts} key={k + "-btn-icon"} className="material-icons">
      {icon}
    </span>
  );
};

// export const flatDatePcker = (opts, idx) => {
//   let dateClearClick = opts.onClearDate;
//   delete opts.onClearDate;

//   return (
//     <div className="flatpickr">
//       <input type="text" placeholder="Select Date.." data-bs-input />

//       <button className="input-button" title="toggle" data-bs-toggle>
//         {/* <i className="icon-calendar"></i> */}
//         <span className="material-icons" style={{ fontSize: "1.5em" }}>
//           save
//         </span>
//       </button>

//       <a className="input-button" title="clear" data-clear>
//         <i className="icon-close"></i>
//       </a>
//     </div>
//   );
// };

// export const flatDatePcker = (opts, idx) => {
//   return (
//     <div className="w-md-50">
//       <input type="text" className="form-control flatpickr" placeholder="" />
//     </div>
//   );
// };

// export const flatDatePicker = (opts, idx) => {
//   let dateClearClick = opts.onClearDate;
//   delete opts.onClearDate;

//   return (
//     <div className="input-group">
//       <Flatpickr
//         options={{
//           // dateFormat: dateFormats.flatpickr,
//           dateFormat: dateFormats.datePickerPicker,
//           enableTime: "true",
//           allowInput: "true",
//         }}
//         // date-time-enable="true"
//         {...opts}
//         className="form-control"
//         type="text"
//         onChange={opts.onChange}
//       />
//       <button
//         key={`datePicker-${opts.key}`}
//         id={`${opts.id}`}
//         type="button"
//         onClick={dateClearClick}
//         className="btn btn-secondary btn-sm"
//       >
//         <CloseIcon fontSize="small" />
//       </button>
//     </div>
//   );
// };

// export const reactDatePicker = (opts, idx) => (
//   <button
//     key={`datePicker-${opts.key}`}
//     id={`${opts.id}`}
//     className="btn btn-secondary btn-sm"
//     type="button"
//     onClick={(e) =>
//       opts.onchange(
//         {
//           target: {
//             name: opts.name,
//             value: "",
//             action: "clear",
//           },
//         },
//         idx
//       )
//     }
//   >
//     <CloseIcon fontSize="small" />
//   </button>
// );

export const locationSelect = (idx, labelPrefix, fieldDef, opts) => {
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  opts = {
    ...opts,
    id: fieldDef.fieldName,
    key: keyId,
    name: fieldDef.fieldName,
    className: "form-control form-control-sm",
    // type: "text",
  };

  opts.value = fieldDef.field;
  if (opts.onchange !== undefined || opts.onchange !== null)
    opts.onChange = (e) => fieldDef.onchange(e, idx);

  return (
    <div className="input-group mb-3 form-floating">
      {/* <LocationFilter opts={opts} /> */}
      LocationFilterMissing
    </div>
  );
};

export const checkBox = (labelPrefix, fieldDef, idx, opts) => {
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  opts = { ...opts, id: fieldDef.fieldName, key: keyId };
  // opts = fieldDef.disabled ? { ...opts, variant: "filled" } : opts;
  opts.checked = fieldDef.field !== null ? fieldDef.field : true;

  return (
    <Fragment>
      <input
        {...opts}
        name={fieldDef.fieldName}
        type="checkbox"
        className="form-check-input mx-2"
        // style={{ width: fieldDef.size }}
        onChange={(e) => fieldDef.onchange(e, idx)}
      />
      {fieldDef.fieldLabel === "" ? (
        ""
      ) : (
        <label className="form-check-label" htmlFor={keyId}>
          {fieldDef.fieldLabel}
        </label>
      )}
    </Fragment>
  );
};

export const showTypeSelect = (k, idx, fieldDef, list) => {
  let keyId = [k, "select", idx].join("-");
  let opts = { id: fieldDef.fieldName, key: keyId };
  return (
    <Fragment>
      <div className="form-floating">
        <select
          className="form-select form-select-sm"
          // style={{ maxWidth: "150px" }}
          {...opts}
          value={fieldDef.field ? fieldDef.field : "Select"}
          name={fieldDef.fieldLabel}
          onChange={(e) => fieldDef.onchange(e, idx)}
        >
          {list.map((item, i) => (
            <option key={keyId + "-" + i} value={item}>
              {item}
            </option>
          ))}
        </select>
        <label htmlFor={keyId}>{fieldDef.fieldLabel}</label>
      </div>
    </Fragment>
  );
};

export const formatValue = (fieldType, value, format) => {
  if (value === null || value === undefined) return "";

  switch (fieldType.toLowerCase()) {
    case "float":
      return value === "" ? "" : parseFloat(value);
    case "datepicker":
      if (value === "") {
        return "";
      } else {
        let newDate = value.isValid
          ? value
          : getValidDate(value, dateFormats.fromDB);
        return newDate.isValid
          ? newDate.toFormat(dateFormats.datePickerLuxon)
          : "";
      }
    case "date":
      if (value === "") {
        return "";
      } else {
        let newDate = value.isValid
          ? value
          : getValidDate(value, dateFormats.fromDB);

        if (!newDate.isValid) return "";
        switch (format) {
          case "iso":
            return newDate.toISO();
          case "jsdate":
            return newDate.toJSDate();
          default:
            if (dateFormats[format]) {
              return newDate.toFormat(dateFormats[format]);
            } else {
              return newDate.toFormat(format);
            }
        }
      }
    default:
      return value;
  }
};

// export const formatValue = (fieldType, value, format) => {
//   if (value === null || value === undefined) return "";

//   switch (fieldType.toLowerCase()) {
//     case "float":
//       return value === "" ? "" : parseFloat(value);
//     case "date":
//       // case "datepicker":
//       if (value === "") return "";
//       if (format.toLowerCase() === "datepicker") {
//         let newDate = value.isValid
//           ? value
//           : getValidDate(value, dateFormats.luxon);
//         // let newDate = value.isValid ? value : getValidDate(value, "dd-LLL-yyy");
//         return newDate.isValid ? newDate.toFormat(dateFormats.luxon) : "";
//       } else {
//         let newDate = value.isValid
//           ? value
//           : getValidDate(value, dateFormats.luxon);

//         if (!newDate.isValid) return "";
//         return format && dateFormats[format]
//           ? newDate.toFormat(dateFormats[format])
//           : newDate.toFormat(dateFormats.luxon);
//       }
//     default:
//       return value;
//   }
// };

// application specific

export const sumValues = (total, newValue) => {
  const numOr0 = (newValue) =>
    isNaN(newValue) || newValue === "" ? 0 : parseFloat(newValue);

  let numTotal = numOr0(total);
  let numNew = numOr0(newValue);

  if (numNew === 0) {
    return numTotal > 0 ? numTotal : total;
  } else if (numTotal === 0) {
    return numNew > 0 ? numNew : total;
  } else {
    return numTotal + numNew;
  }

  // if (numTotal === 0 && numNew === 0) return total;
  // if (numTotal === 0 && numNew > 0) return newValue;
  // if (numTotal > 0 && numNew === 0) return numTotal;
  // if (numTotal > 0 && numNew > 0) return numTotal + numNew;
};

export const addDays = (start, days) => {
  let newDate;
  if (start.isValid) {
    newDate = start;
  } else {
    newDate = getValidDate(start, dateFormats.fromDB);
  }

  if (!newDate.isValid || !hasVal(days) || isNaN(days)) {
    return start;
  } else {
    let millis = Duration.fromObject({ days: days })
      .shiftTo("milliseconds")
      .toObject();
    return newDate.plus(millis.milliseconds);
    // return newDate.plus({ days: parseInt(days) });
  }
};

// date time functions

export const isValidDate = (d, formatSuggested) => {
  if (!d || d === undefined || d === "") return false;
  let newDate = formatSuggested
    ? checkValidDates(d, formatSuggested)
    : checkValidDates(d);
  return newDate.isValid;
};

export const checkValidDates = (d, formatSuggested) => {
  let newDate;
  if (d?.isValid) return d;
  if (formatSuggested)
    newDate =
      formatSuggested.toLowerCase() === "jsdate"
        ? new DateTime.fromJSDate(d)
        : formatSuggested.toLowerCase() === "iso"
        ? new DateTime.fromISO(d)
        : new DateTime.fromFormat(d, formatSuggested);
  if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd-LLL-yyyy");
  if (!newDate?.isValid) newDate = new DateTime.fromJSDate(d);
  if (!newDate?.isValid) newDate = new DateTime.fromJSDate(d);
  if (!newDate?.isValid) newDate = new DateTime.fromISO(d);
  if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd LLL yyyy");
  if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd-LL-yyyy");
  if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd/LL/yyyy");
  return newDate;
};

export const getValidDate = (d, formatSuggested) => {
  if (!d || d === undefined || d === "") return false;
  if (d?.isValid) return d;
  let newDate = formatSuggested
    ? checkValidDates(d, formatSuggested)
    : checkValidDates(d);
  return newDate.isValid ? newDate : d;
};

export const returnIsNewVsOldDate = (newDate, comp, origDate) => {
  let result = newVsOldDate(newDate, comp, origDate);

  if (result === null) {
    let oDate = getValidDate(origDate, dateFormats.luxon);
    let nDate = getValidDate(newDate, dateFormats.luxon);

    if (!oDate.isValid && !nDate.isValid) return null;
    if (!oDate.isValid && nDate.isValid) return newDate;
    if (oDate.isValid && !nDate.isValid) return origDate;

    return origDate;
  } else if (result === true) {
    return newDate;
  } else if (result === false) {
    return origDate;
  } else {
    return origDate;
  }
};

// compare if new date is something compared to old date
export const newVsOldDate = (newDate, comp, origDate) => {
  let oDate = getValidDate(origDate, dateFormats.luxon);
  let nDate = getValidDate(newDate, dateFormats.luxon);

  if (!oDate.isValid && !nDate.isValid) return null;
  if (!oDate.isValid && nDate.isValid) return null;
  if (oDate.isValid && !nDate.isValid) return null;
  if (comp === "<" || comp === "lt") return nDate < oDate;
  if (comp === "<=" || comp === "lte") return nDate <= oDate;
  if (comp === ">" || comp === "gt") return nDate > oDate;
  if (comp === ">=" || comp === "gte") return nDate >= oDate;
  if (comp === "=" || comp === "eq") return nDate === oDate;
  // if (comp === "<" || comp === "lt") return oDate < nDate;
  // if (comp === "<=" || comp === "lte") return oDate <= nDate;
  // if (comp === ">" || comp === "gt") return oDate > nDate;
  // if (comp === ">=" || comp === "gte") return oDate >= nDate;
  // if (comp === "=" || comp === "eq") return oDate === nDate;
};

// export const compareDates = (origDate, newDate, comp) => {
//   let oDate = getValidDate(origDate);
//   let nDate = getValidDate(newDate);

//   if (!oDate.isValid && !nDate.isValid) return null;
//   if (!oDate.isValid && nDate.isValid) return newDate;
//   if (oDate.isValid && !nDate.isValid) return origDate;
//   if (comp === "<" || comp === "lt") return nDate < oDate ? newDate : origDate;
//   if (comp === "<=" || comp === "lte")
//     return nDate <= oDate ? newDate : origDate;
//   if (comp === ">" || comp === "gt") return nDate > oDate ? newDate : origDate;
//   if (comp === ">=" || comp === "gte")
//     return nDate >= oDate ? newDate : origDate;
//   if (comp === "=" || comp === "eq")
//     return nDate === oDate ? newDate : origDate;
// };

export const fromToDate = (d) => {
  return DateTime.fromFormat(d, "dd-LLL-yyyy");
};

export const formatDateString = (d) => {
  let newDate = getValidDate(d, dateFormats.mongodb);
  return !newDate.isValid ? newDate : newDate.toFormat(dateFormats.summaryDate);
  // return !newDate.isValid ? newDate : newDate.toFormat("dd-LLL-yyyy");
};

export const formatTime = (d) => {
  let newDate = getValidDate(d);
  return !newDate.isValid
    ? newDate
    : newDate.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const formatDate = (d) => {
  return !d.isValid ? d : d.toFormat(dateFormats.luxon);
};

// logic checks

export const hasObjValue = (value) => (value?.value ? value.value : value);

export const hasObjLabel = (value) => (value?.label ? value.label : value);

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && obj.hasOwnProperty(key);

export const emptyObj = (obj) => !obj || obj === null;

export const zeroString = (val) => val === "";

export const hasVal = (val) =>
  !(val === undefined || val === null || val === "");
