import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const Private = ({ Component }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;

  return !isAuthenticated && !loading ? (
    <Navigate to="/login" />
  ) : (
    <Component />
  );
};

export default Private;

// const PrivateRoute = ({ component: Component, ...rest }) => {

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         !isAuthenticated && !loading ? (
//           <Navigate
//             to={{
//               pathname: "/login",
//               state: { from: props.location },
//             }}
//           />
//           // <Navigate
//           //   to={{
//           //     pathname: "/login",
//           //     state: { from: props.location },
//           //   }}
//           // />
//         ) : (
//           <Component {...props} />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;
