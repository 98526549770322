import React, {
  useContext,
  // , useEffect
} from "react";
import { Fragment } from "react";
import AuthContext from "../../context/auth/authContext";
import JobContext from "../../context/job/jobContext";
import { userCreatedBid, userCreatedJob } from "../../utils/common";

const BidList = ({ job, showMore }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const jobContext = useContext(JobContext);
  const { setCurrentBid, actionTheJob } = jobContext;

  const showBidDetail = (e, bid, edit) => {
    setCurrentBid(bid);
  };

  const approveTheBid = (e, job, bid) => {
    e.preventDefault();
    actionTheJob(job._id, bid._id, "approve");
  };

  const rejectTheBid = (e, job, bid) => {
    e.preventDefault();
    actionTheJob(job._id, bid._id, "reject");
  };

  const confirmTheJob = (e, job, bid) => {
    e.preventDefault();
    actionTheJob(job._id, bid._id, "confirm");
  };

  const showActionButtons = (k, job, bid) => {
    let statusLower = job.status.toLowerCase();

    if (statusLower === "bid selected") {
      if (bid.status.toLowerCase() === "selected") {
        return (
          <Fragment>
            {userCreatedBid ? (
              <li>
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  key={`jobItem-btn-confirm-${k}`}
                  onClick={(e) => confirmTheJob(e, job, bid)}
                >
                  Confirm Job
                </button>
              </li>
            ) : (
              ""
            )}
            <li>
              <button
                type="button"
                className="btn btn-sm btn-link"
                key={`jobItem-btn-reject-${k}`}
                onClick={(e) => rejectTheBid(e, job, bid)}
              >
                Reject
              </button>
            </li>
          </Fragment>
        );
      }
    } else if (statusLower === "confirmed") {
    } else if (statusLower === "posted") {
      if (userCreatedBid) {
        return (
          <li>
            <button
              type="button"
              className="btn btn-sm btn-link"
              key={`jobItem-btn-addBid-${k}`}
              onClick={(e) => showBidDetail(e, bid, true)}
            >
              Edit
            </button>
          </li>
        );
      }
    } else {
      return (
        <li>
          <button
            type="button"
            className="btn btn-sm btn-link"
            key={`jobItem-btn-approve-${k}`}
            onClick={(e) => approveTheBid(e, job, bid)}
          >
            Approve
          </button>
        </li>
      );
    }
  };

  const showBidList = (bids, job) => {
    let usersJob = userCreatedJob(job, user);

    if (!showMore || !showMore.includes(job._id)) return "";
    return (
      <table className="table table-striped" key={`bidList-table-${job._id}`}>
        <tbody>
          {bids.map((bid, idx) => {
            let usersBid = userCreatedBid(bid, user);

            let k = job._id + "-" + idx;
            if (!usersJob && !usersBid) return false;
            return (
              <tr key={`bidList-row-1-${k}`}>
                <td key={`bidList-col-1-${k}`} className="small">
                  <div
                    key={`bidList-flex-1-${k}`}
                    className="d-flex align-items-start flex-column bd-highlight"
                  >
                    <div
                      key={`bidList-txtFormat-1-${k}`}
                      className="bd-highlight"
                    >
                      {usersBid ? "Your Bid" : bid.company}
                    </div>
                  </div>
                </td>
                <td className="small" key={`bidList-col-2-${k}`}>
                  <div
                    className="d-flex align-items-center flex-column bd-highlight"
                    key={`bidList-flex-2-${k}`}
                  >
                    <div
                      key={`bidList-txtFormat-2-${k}`}
                      className="bd-highlight"
                    >
                      {bid.status}
                    </div>
                  </div>
                </td>
                <td className="small" key={`bidList-col-3-${k}`}>
                  <div
                    className="d-flex align-items-end flex-column bd-highlight "
                    key={`bidList-flex-3-${k}`}
                  >
                    <div
                      className="bd-highlight"
                      key={`bidList-txtFormat-3-${k}`}
                    >
                      ${bid.amount}
                    </div>
                  </div>
                </td>
                <td key={`bidList-col-4-${k}`} className="small p-1">
                  <div className="btn-group" role="group">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className="btn btn-primary btn-sm dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Actions
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <li>
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          key={`jobItem-btn-addBid-${k}`}
                          onClick={(e) => showBidDetail(e, bid, false)}
                        >
                          View
                        </button>
                      </li>
                      {showActionButtons(k, job, bid)}
                    </ul>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return showBidList(job.bids, job);
};

export default BidList;
