import React, { Fragment, useContext, useEffect } from "react";
import { useState } from "react";

import AuthContext from "../../context/auth/authContext";
import JobContext from "../../context/job/jobContext";
import {
  haveUser,
  statusColors,
  userCreatedJob,
  userWonJob,
} from "../../utils/common";
import BidList from "../bids/BidList";
import AddBid from "./AddBid";

const JobItem = ({ job }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const jobContext = useContext(JobContext);
  const { setCurrentJob, currentJob, showHideBids, showBids } = jobContext;

  const [showMore, setShowMore] = useState([]);

  useEffect(() => {
    if (showBids) setShowMore(showBids);

    // eslint-disable-next-line
  }, [currentJob]);

  const showJobDetail = (e, job) => {
    setCurrentJob(job);
  };

  const showTheBids = (jobId) => {
    let emptyOrNoLength = !showMore || !showMore.length;
    let bidInArray = showMore.includes(jobId);

    setShowMore(
      !bidInArray || emptyOrNoLength
        ? [...showMore, jobId]
        : showMore.filter((bids) => (bids === jobId ? false : true))
    );
    showHideBids(jobId);
  };

  const addBidModal = (k) => {
    return <AddBid key={`bidAdd-AddBidCtrl-${k}`} jobId={job._id} />;
  };

  const bidSummary = (bids, job) => {
    let minBid;
    let bidCount = bids.length;
    for (let bid of bids) {
      minBid = !minBid ? bid.amount : bid.amount < minBid ? bid.amount : minBid;
    }
    return (
      <Fragment>
        <span>{"(" + bidCount + ")"}</span>
        <button
          key={`showmore-${job._id}`}
          className="btn btnIcon"
          onClick={() => showTheBids(job._id)}
        >
          <span className="material-icons align-bottom">more_horiz</span>
        </button>{" "}
      </Fragment>
    );
  };

  let k = job._id;
  // let haveUser = user && user?._id;
  const loggedIn = haveUser(user);
  const usersJob = userCreatedJob(job, user);
  const userWon = userWonJob(job, user, "Bid Confirmed");

  let hideAddBidButton =
    !loggedIn ||
    usersJob ||
    ["confirmed", "in progress", "completed"].includes(
      job.status.toLowerCase()
    );
  return (
    <Fragment>
      <div className={`card`} key={`jobItem-row-1-${k}`}>
        <div
          className={`card-header mb-1 pt-3 p-1 ${
            usersJob ? "bg-success" : "bg-primary"
          }`}
          key={`jobItem-container-${k}`}
        >
          <div className="container" key={`jobItem-row-1-${k}`}>
            <div className="row text-white" key={`jobItem-row-1-${k}`}>
              <div className="col h5" key={`jobItem-row-1-col-1-${k}`}>
                {job.category}
              </div>

              <div className="col small" key={`jobItem-row-1-col-2-${k}`}>
                <div
                  key={`jobItem-row-1-col-3-flex-${k}`}
                  className="d-flex align-items-end flex-column bd-highlight mb-1"
                >
                  <h5>
                    <span
                      className={`badge rounded-pill border border-light bg-${
                        statusColors[job.status.toLowerCase()]
                      }`}
                    >
                      {job.status}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="col small" key={`jobItem-row-1-col-3-${k}`}>
                <div
                  key={`jobItem-row-1-col-3-flex-${k}`}
                  className="d-flex align-items-end flex-column bd-highlight mb-1"
                >
                  <button
                    className="btn btn-sm btn-light"
                    key={`jobItem-row-1-col-1-MoreDetails-${k}`}
                    onClick={(e) => showJobDetail(e, job)}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="row text-white mb-2 justify-content-between"
              key={`jobItem-row-3-${k}`}
            >
              <div className="col-auto" key={`jobItem-row-3-col-1-${k}`}>
                <div className="d-flex align-items-center h-100">
                  <span className="material-icons inline">location_on</span>{" "}
                  {job.area}
                </div>
              </div>
              <div className="col-auto" key={`jobItem-row-3-col-2-${k}`}>
                <div
                  key={`bidList-flex-1-${k}`}
                  className="d-flex align-items-end flex-column bd-highlight mb-1"
                >
                  {userWon || usersJob ? (
                    <span className={`badge rounded-pill border border-light`}>
                      {userWon ? "JOB WON" : "YOUR JOB"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-auto" key={`jobItem-row-3-col-3-${k}`}>
                <div
                  key={`bidList-flex-1-${k}`}
                  className="d-flex align-items-end flex-column bd-highlight mb-1"
                >
                  {loggedIn && job.user.name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-2" key={`jobItem-row-2-col-1-${k}`}>
          <div className="row" key={`jobItem-row-2-${k}`}>
            <div className="col" key={`jobItem-row-2-col-1-${k}`}>
              {job.description}
            </div>
          </div>
          <div
            className="row mt-2 justify-content-between"
            key={`jobItem-row-3-${k}`}
          >
            {!loggedIn ? (
              <div
                className="col-12 align-middle"
                key={`jobItem-row-2-col-2-${k}`}
              >
                <center>
                  {!loggedIn ? "Please login to add or view bids" : ""}
                </center>
              </div>
            ) : (
              <Fragment>
                <div
                  className="col-8 align-middle"
                  key={`jobItem-row-2-col-2-${k}`}
                >
                  <span className="h4 h-100">
                    <span className="d-flex align-items-center h-100">
                      BIDS&nbsp;
                      {loggedIn ? bidSummary(job.bids, job) : ""}
                      &nbsp;
                    </span>
                  </span>
                </div>
                <div className="col-4" key={`jobItem-row-2-col-3-${k}`}>
                  <div
                    key={`bidList-flex-1-${k}`}
                    className="d-flex align-items-end flex-column bd-highlight mb-1"
                  >
                    {hideAddBidButton ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        key={`jobItem-btn-addBid-${k}`}
                        data-bs-toggle="modal"
                        data-bs-target="#addModal"
                      >
                        ADD BID
                      </button>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="row" key={`jobItem-row-4-${k}`}>
            <div className="col" key={`jobItem-row-4-col-1-${k}`}>
              {loggedIn ? (
                <BidList showMore={showMore} job={job} />
              ) : (
                // <BidList showMore={showMore} job={job} />
                ""
              )}
            </div>
          </div>
        </div>
        {/* <div className="card-footer" key={`jobItem-footer-2-col-1-${k}`}></div> */}
      </div>
      {addBidModal(job._id)}
      {/* {addBidModal(job._id, newBid)} */}
    </Fragment>
  );
};

export default JobItem;
