import React, { useContext, useEffect } from "react";

import JobContext from "../../context/job/jobContext";
import BidItem from "./BidItem";

// import { List } from "@mui/material";

const BidTag = () => {
  const jobContext = useContext(JobContext);
  const {
    // setCurrentBid,
    clearCurrentBid,
  } = jobContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const clearBid = (e) => {
    clearCurrentBid();
  };

  return (
    <div className="container">
      <div
        className="d-flex align-items-end flex-column bd-highlight"
        key={`bids-flex-1`}
      >
        <div className="bd-highlight" key={`bids-flex-format-1`}>
          <button
            className="btn btn-sm btn-primary"
            key={`bids-flex-backBtn-1`}
            onClick={(e) => clearBid(e)}
          >
            Back
          </button>
        </div>
      </div>
      <BidItem />
      <br />
      <div
        key={`gridItem-flex-2`}
        className="d-flex align-items-end flex-column bd-highlight"
      >
        <div className="bd-highlight" key={`gridItem-flex-format-2`}>
          <button
            className="btn btn-sm btn-primary"
            key={`bids-flex-backBtn-2`}
            onClick={(e) => clearBid(e)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default BidTag;
