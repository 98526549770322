import React, { useReducer } from "react";
import axios from "axios";
import CompanyContext from "./companyContext";
import companyReducer from "./companyReducer";

import {
  GET_COMPANYS,
  ADD_COMPANY,
  // DELETE_COMPANY,
  UPDATE_MEMBER,
  CLEAR_COMPANYS,
  UPDATE_COMPANY,
  // FILTER_COMPANYS,
  SET_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  // CLEAR_FILTER,
  COMPANY_ERROR,
  GET_USERS_COMPANYS,
  // CLEAR_ERRORS,
} from "../types";

const CompanyState = (props) => {
  const initialState = {
    companies: null,
    usersCompanies: null,
    currentCompany: null,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(companyReducer, initialState);

  // Load User
  const getCompanies = async (filter) => {
    // if token exists, set token in config
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // try getting user from auth
      const res = await axios.post("/api/companies", config);
      // const res = await axios.get("/api/companys");

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_COMPANYS,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: CLEAR_COMPANYS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log("got error");
      dispatch({ type: COMPANY_ERROR });
    }
  };

  // Load User
  const getUsersCompanies = async (userId) => {
    // if token exists, set token in config
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // try getting user from auth
      const res = await axios.post("/api/companies", { userId }, config);

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_USERS_COMPANYS,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: CLEAR_COMPANYS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log("got error");
      dispatch({ type: COMPANY_ERROR });
    }
  };

  // Login User
  const addCompany = async (company) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/companys/add", { company }, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: ADD_COMPANY,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: COMPANY_ERROR,
      });
    }
  };

  // Login User
  const updateCompany = async (company) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/companies/update/",
        { company },
        config
      );

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: UPDATE_COMPANY,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: COMPANY_ERROR,
      });
    }
  };

  // Login User
  const updateMembers = async (companyId, userId, action) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/companies/updateMembers",
        { companyId, userId, action },
        config
      );

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: UPDATE_MEMBER,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: COMPANY_ERROR,
      });
    }
  };

  const setCurrentCompany = (data) => {
    dispatch({ type: SET_CURRENT_COMPANY, payload: data });
  };

  const clearCurrentCompany = () => {
    dispatch({ type: CLEAR_CURRENT_COMPANY });
  };

  return (
    <CompanyContext.Provider
      value={{
        getCompanies,
        companies: state.companies,
        getUsersCompanies,
        usersCompanies: state.usersCompanies,
        addCompany,
        updateCompany,
        setCurrentCompany,
        clearCurrentCompany,
        currentCompany: state.currentCompany,
        updateMembers,
        loading: state.loading,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyState;
