import React, { useContext, useEffect } from "react";

import { List } from "@mui/material";

import JobContext from "../../context/job/jobContext";
import JobList from "./JobList";
import Bids from "../pages/Bids";
import JobDetail from "./JobDetail";

const JobTag = () => {
  const jobContext = useContext(JobContext);
  const { currentJob, currentBid } = jobContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, [currentBid, currentJob]);

  return (
    <List key="list-0">
      {currentBid ? <Bids /> : currentJob ? <JobDetail /> : <JobList />}
    </List>
  );
};

export default JobTag;
